import { gql } from '@apollo/client';
import { setLoading, setSession, logout } from 'Graphql/store';

const POST_REFRESH = {
  query: gql`
    query RefreshToken($refreshToken: String!) {
      refreshToken(refreshToken: $refreshToken) {
        accessToken
        refreshToken
        data {
          uid
          firstname
          lastname
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    onCompleted: ({ refreshToken: responseData, errors }) => {
      if (responseData) {
        const { accessToken, refreshToken, data: refreshTokenData } = responseData;
        const { firstname: userFirstName, lastname: userLastName, uid: userId } = refreshTokenData;
        setSession({ accessToken, refreshToken, userId, userFirstName, userLastName });
        setLoading(false);
      }
      if (errors) {
        logout();
        setLoading(false);
      }
    },
    onError: (apolloError) => {
      if (apolloError) {
        logout();
        setLoading(false);
      }
    },
    context: {
      forRefreshToken: true,
    },
  },
};

export default POST_REFRESH;
