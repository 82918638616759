import * as Sentry from '@sentry/react';
import { authStore } from './model';

const getExpirationDate = () => new Date().getTime() + 55 * 60 * 1000;

export const setExpirationDate = () => localStorage.setItem('expirationDate', getExpirationDate());

export const setRefreshToken = (refreshToken) => localStorage.setItem('refreshToken', refreshToken);

export const setSession = ({ refreshToken, accessToken, userId, userFirstName, userLastName }) => {
  setRefreshToken(refreshToken);
  setExpirationDate();
  Sentry.setContext('Admin', { userId, name: `${userFirstName} ${userLastName}`.trim() });
  authStore({ ...authStore(), accessToken, userId, userFirstName, userLastName });
};

export const logout = () => {
  localStorage.clear();
  authStore({
    ...authStore(),
    accessToken: null,
    userId: null,
    userFirstName: null,
    userLastName: null,
  });
};
