import { authStore } from './model';

export const getAuthStore = () => authStore();

export const getExpirationDate = () => {
  return localStorage.getItem('expirationDate') ? localStorage.getItem('expirationDate') : null;
};

export const getRefreshToken = () =>
  localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken') : null;

export const isExpired = () => {
  const date = getExpirationDate();
  if (date) {
    return new Date().getTime() > parseInt(date, 10);
  }
  return true;
};
