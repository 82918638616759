import { ApolloClient, InMemoryCache, HttpLink, from, concat } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';
import {
  getRefreshToken,
  isExpired,
  setLoading,
  logout,
  getAuthStore,
  setExpirationDate,
  setSession,
} from 'Graphql/store';
import POST_REFRESH from 'Graphql/queries/auth/refreshToken';

const httpLink = new HttpLink({ uri: process.env.API_GATEWAY });

const refreshQuery = async (currentRefreshToken) => {
  const refreshQueryClient = new ApolloClient({ link: httpLink, cache: new InMemoryCache() });
  return refreshQueryClient.query({
    query: POST_REFRESH.query,
    variables: { refreshToken: currentRefreshToken },
    ...POST_REFRESH.policies,
  });
};

const buildErrorScope = (scope, operation, response) => {
  if (operation) {
    scope.setTag('kind', operation?.operationName);
    scope.setExtra('query', operation?.query?.loc?.source);
    scope.setExtra('variables', operation?.variables);
  }
  scope.setExtra('response', response?.data);
};

const getAccessToken = async (currentRefreshToken, errorOrigin) => {
  if ((currentRefreshToken && isExpired()) || errorOrigin) {
    try {
      setExpirationDate();
      const { data: queryData } = await refreshQuery(currentRefreshToken);
      const { accessToken, refreshToken, data: refreshTokenData } = queryData.refreshToken || {};
      const { firstname: userFirstName, lastname: userLastName, uid: userId } = refreshTokenData;
      setSession({ accessToken, refreshToken, userId, userFirstName, userLastName });
      setLoading(false);
      return { accessToken };
    } catch (error) {
      logout();
      setLoading(false);
      return { accessToken: false };
    }
  }
  setLoading(false);
  return getAuthStore();
};

const authMiddleware = setContext(async (_, { headers, forRefreshToken }) => {
  const currentRefreshToken = getRefreshToken();
  const { accessToken } = await getAccessToken(currentRefreshToken);
  return {
    headers: {
      ...headers,
      authorization: accessToken && !forRefreshToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, response, forward }) => {
  if (graphQLErrors) {
    const {
      headers: oldHeaders,
      forRefreshToken,
      ignoreError,
      ignorePattern,
    } = operation.getContext();
    graphQLErrors.forEach(async ({ extensions, message, locations, path }) => {
      if (extensions.code === 'UNAUTHENTICATED') {
        const { accessToken } = await getAccessToken(getRefreshToken(), true);
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: accessToken && !forRefreshToken ? `Bearer ${accessToken}` : '',
          },
        });
        return forward(operation);
      }
      if (ignoreError) {
        return null;
      }
      if (ignorePattern) {
        const { pattern = [], mandatory = true } = ignorePattern || {};
        if (mandatory && pattern.every((term) => message?.includes(term))) return null;
        if (!mandatory && pattern.some((term) => message?.includes(term))) return null;
      }
      return Sentry.withScope((scope) => {
        buildErrorScope(scope, operation, response);
        Sentry.captureMessage(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    });
  }
  if (networkError) {
    Sentry.withScope((scope) => {
      buildErrorScope(scope, operation, response);
      Sentry.captureException(`[Network error]: ${networkError}`);
    });
    Sentry.captureException(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, concat(authMiddleware, httpLink)]),
});

export default client;
