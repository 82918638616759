import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { getAuthStore, getRefreshRoute } from 'Graphql/store';
import LoadingScreen from 'Components/ui/LoadingScreen';

const GuestGuard = () => {
  const navigate = useNavigate();
  const { accessToken } = getAuthStore();
  const refreshRoute = getRefreshRoute();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (accessToken) {
      navigate(refreshRoute || '/app/general/dashboard', { replace: true });
    } else {
      setLoading(false);
    }
  }, [accessToken, navigate, refreshRoute]);

  if (loading) return <LoadingScreen fullScreen size="big" />;

  return <Outlet />;
};

export default GuestGuard;
